// eslint-disable-next-line
export default {
  id: 'sopa_de_batata_ninja',
  type: 'ninja',
  title: "Sopa de batata (Ninja's Version)",
  ingredients: [
    '2 batatas',
    '1 cebola roxa',
    '1 alho poró',
    'Azeite',
    'Queijo parmessão (Opcional)',
  ],
  parts: [
    {
      title: 'Batatas',
      methodOfPreparation: [
        'Descasque a batata e parta em quatro partes para cozinhar mais rápido',
        'Coloque as batatas no pote sem acessório',
        'Coloque 250ml de água com sal no pote',
        'Coloque no modo de pressão, high e quick release por 18 minutos',
      ],
    },
    {
      title: 'Tempero',
      methodOfPreparation: [
        'Corte a cebola',
        'Corte o alho poró ',
        'Coloque a cebola e o alho poró com azeite na frigideira',
        'Quando os dois estiverem dourados, está pronto',
      ],
    },
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque tudo no liquidificador (parmessão opcional) com um pouco da água que ela cozinhou',
        'Bata até ficar uniforme',
      ],
    },
  ],
};
