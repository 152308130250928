// eslint-disable-next-line
export default {
  id: 'feijao',
  type: 'ninja',
  title: 'Feijão',
  ingredients: [
    '? Sal',
    '1 cebola',
    '? azeite',
    '1 pacote de feijão',
    '1 jirimum (opcional)',
    '1 kit feijoada (linguiça, charque,...)',
  ],
  parts: [
    {
      title: 'Feijão de molho',
      methodOfPreparation: [
        'Deixe o feijão de molho por 24 horas, trocando a água quando ela for ficando escura',
      ],
    },
    {
      title: 'Feijão',
      methodOfPreparation: [
        'Coloque a cebola no pote no modo sear/saute com azeite',
        'Quando começar a ficar dourada, coloque o kit feijoada e termine de refogar',
        'Coloque o feijã, água cobrindo o feijão (cuidado com o máximo), o jirimum (opcional) e sal (não muito porque o kit é bastante salgado sem acessório',
        'Coloque no modo de pressão, high e delayed release por 22 minutos',
      ],
    },
  ],
};
