// eslint-disable-next-line
export default {
  id: 'macaxeira',
  type: 'ninja',
  title: 'Macaxeira',
  ingredients: ['? Sal', '1 pacote de macaxeira'],
  parts: [
    {
      title: 'Macaxeira',
      methodOfPreparation: [
        'Coloque o saco inteiro da macaxeira no pote sem acessório',
        'Cubra todas as macaxeiras com água e coloque sal',
        'Coloque no modo de pressão, high e quick release por 22 minutos',
      ],
    },
  ],
};
