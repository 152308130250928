import Recipe from '_Api/Recipe';
import { SAVOURY, SWEET, BASIC, NINJA } from '_Shared/constants';
import { users } from '_Api/users';
import { getRecipesContext } from '_Api/loadFiles';

function getRecipesByPersonId(personId, search = '') {
  const recipes = _getRecipesByPersonId(personId, search);
  const recipesWithPerson = recipes.map((recipe) => ({
    ...recipe,
    personId,
  }));

  return {
    [SAVOURY]: recipesWithPerson.filter((item) => item.type === SAVOURY),
    [SWEET]: recipesWithPerson.filter((item) => item.type === SWEET),
    [BASIC]: recipesWithPerson.filter((item) => item.type === BASIC),
    [NINJA]: recipesWithPerson.filter((item) => item.type === NINJA),
  };
}

function getRecipeById(recipeId, personId) {
  const recipes = getRecipesByPersonId(personId);
  const allRecipes = [].concat.apply([], Object.values(recipes));

  return allRecipes.find((recipe) => recipe.id === recipeId);
}

function getUsers() {
  return users.map((user) => ({
    ...user,
    recipes: getRecipesByPersonId(user.key),
  }));
}

function getRecipesBySearch(search) {
  const normalizedSearch = _normalizeSearch(search);
  const recipes = users.map((user) =>
    getRecipesByPersonId(user.key, normalizedSearch)
  );

  const allRecipes = recipes.reduce((accumulator, item) => {
    return {
      [SAVOURY]: [].concat.apply(accumulator[SAVOURY] || [], item[SAVOURY]),
      [SWEET]: [].concat.apply(accumulator[SWEET] || [], item[SWEET]),
      [BASIC]: [].concat.apply(accumulator[BASIC] || [], item[BASIC]),
      [NINJA]: [].concat.apply(accumulator[NINJA] || [], item[NINJA]),
    };
  }, {});

  return {
    [SAVOURY]: _sort(allRecipes[SAVOURY]),
    [SWEET]: _sort(allRecipes[SWEET]),
    [BASIC]: _sort(allRecipes[BASIC]),
    [NINJA]: _sort(allRecipes[NINJA]),
  };
}

function _getRecipesByPersonId(personId, search) {
  const recipesContext = getRecipesContext(personId);
  const recipes = _loadAllFrom(recipesContext);

  const recipeFiltered = recipes.filter((recipe) =>
    recipe.id.replace('_', ' ').includes(search)
  );

  return _sort(recipeFiltered);
}

function _loadAllFrom(context) {
  const files = context.keys();
  const recipes = [];

  for (let file of files) {
    const recipe = context(file).default;

    recipes.push(new Recipe(recipe));
  }

  return recipes;
}

function _sort(recipes) {
  return recipes.sort((a, b) => a.title.localeCompare(b.title));
}

function _normalizeSearch(search) {
  let normalizedSearch = decodeURIComponent(search.toLocaleLowerCase());

  normalizedSearch = normalizedSearch.replace(/[àáâãäå]/g, 'a');
  normalizedSearch = normalizedSearch.replace(/[éèêë]/g, 'e');
  normalizedSearch = normalizedSearch.replace(/[íìîï]/g, 'i');
  normalizedSearch = normalizedSearch.replace(/[óòõôö]/g, 'o');
  normalizedSearch = normalizedSearch.replace(/[úùûü]/g, 'u');
  normalizedSearch = normalizedSearch.replace(/[ç]/g, 'c');

  return normalizedSearch;
}

// eslint-disable-next-line
export default {
  getRecipesByPersonId,
  getRecipeById,
  getUsers,
  getRecipesBySearch,
};
