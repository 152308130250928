// eslint-disable-next-line
export default {
  id: 'petit_gateau_fit',
  type: 'sweet',
  title: 'Petit Gateau fit',
  ingredients: [
    '55g de manteiga',
    '1 colher de sopa de aveia',
    '55g de chocolate amargo 85%',
    '2 ovos',
    '3.5 colheres de sopa de adoçante em pó (stevia, erythritol ou xylitol)',
  ],
  parts: [
    {
      title: 'Petit Gateau fit',
      methodOfPreparation: [
        'Derrete a manteiga e os chocolates juntos',
        'Em uma vasilha a parte bate os ovos com um fuê',
        'Mistura todos os ingredientes até ficar uniforme',
        'Leva ao forno por 10 minutos à 160 graus',
      ],
    },
  ],
};
