// eslint-disable-next-line
export default {
  id: 'frango_desfiado',
  type: 'ninja',
  title: 'Frango desfiado',
  ingredients: [
    '? Sal',
    '? papikra',
    '? alho granulado',
    '1 limão',
    '3 pedaços de peito grandes',
  ],
  parts: [
    {
      title: 'Frango desfiado',
      methodOfPreparation: [
        'Tempere o frango com sal, limão, papikra e alho granulado',
        'Coloque no pote com 250ml de água sem acessório',
        'Coloque no modo de pressão, high e quick release por 8 minutos',
      ],
    },
  ],
};
