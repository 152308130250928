// eslint-disable-next-line
export default {
  id: 'creapioca',
  type: 'savoury',
  title: 'Crepioca',
  ingredients: [
    '? Sal',
    '1 colher e meia rasa de tapioca',
    '1 ovo',
    '? parmessão (Opcional)',
  ],
  parts: [
    {
      title: 'Crepioca',
      methodOfPreparation: [
        'Coloque a frigideira para esquentar no fogo médio',
        'Coloque todos os ingredientes em um pote e misture até ficar uniforme',
        'Quando a frigideira estiver bem quente, coloque os ingredientes misturados (se não for anti aderente, coloque manteiga)',
        'Tampe a frigideira',
        'Quando ela começar a inchar, vire',
        'Asse até os dois lados ficarem secos',
      ],
    },
  ],
};
